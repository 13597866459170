@use "breakpoints";
@use "colors";
@use "layout";
@use "links";
@use "typography";

/********************************************************************   */

.container {
  display: flex;
  margin: 0 auto;
  overflow-x: hidden;
  user-select: none;
}

.highlights {
  @include layout.module-content;

  list-style: none;
  margin-block: auto 0;
  position: static;
}

.highlight {
  .photo {
    inline-size: 100%;
    inset-block-start: 0;
    inset-inline-start: 0;
    opacity: 0;
    position: absolute;
    transition: opacity 0.5s;
    z-index: 1;
  }
}

.highlight-info {
  user-select: none;
}

.highlight-title {
  @include typography.small-headline;
}

.highlight-link {
  @include links.regular-text-link-dark;
}

/********************************************************************   */

@media (width < breakpoints.$md) {
  .container {
    block-size: var(--mobile-page-hero-block-size);
  }

  .highlights {
    align-items: stretch;
    display: grid;
    grid-gap: 40px;
    grid-template-columns: 0 repeat(3, calc(279px)) 0;
    grid-template-rows: minmax(150px, 1fr);
    margin-block-end: 23px;
    margin-inline-start: -16px;
    padding: 0;

    // needed to maintain card spacing...
    &::before,
    &::after {
      content: "";
    }
  }

  .highlight {
    .photo {
      block-size: 100%;
      overflow: hidden;
    }
  }

  .highlight-info {
    background-color: colors.$white;
    block-size: auto;
    color: colors.$off-black;
    padding: 40px 24px;
    position: relative;
    transition: transform 0.5s;
    z-index: 2;
  }

  .highlight-title {
    margin-block-end: 24px;
  }

  .highlight-text {
    margin-block-end: 15px;
  }

  .highlight-link {
    .cta-text {
      display: none;
    }
  }

  /********************************************************************   */

  .highlights[data-current-index="0"] .highlight:nth-child(1),
  .highlights[data-current-index="1"] .highlight:nth-child(2),
  .highlights[data-current-index="2"] .highlight:nth-child(3) {
    .photo {
      opacity: 1;
    }
  }

  .highlights[data-current-index="1"] {
    .highlight:nth-child(1) .highlight-info,
    .highlight:nth-child(2) .highlight-info,
    .highlight:nth-child(3) .highlight-info {
      transform: translateX(-114%);
    }
  }

  .highlights[data-current-index="2"] {
    .highlight:nth-child(1) .highlight-info,
    .highlight:nth-child(2) .highlight-info,
    .highlight:nth-child(3) .highlight-info {
      transform: translateX(-228%);
    }
  }
}

/********************************************************************   */

@media (width >= breakpoints.$md) {
  .container {
    aspect-ratio: 8 / 5;
  }

  .highlights {
    align-items: start;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(3, auto);
    margin-block-end: 145px;
  }

  .highlight {
    .photo {
      block-size: 100%;
    }
  }

  .highlight-info {
    background-color: rgb(255 255 255 / 0%);
    padding: 0 24px 32px;
    position: relative;
    transition: background 0.5s;
    z-index: 2;
  }

  .highlight-title {
    border-block-start: 1px solid colors.$white;
    color: colors.$white;
    margin-block-end: 24px;
    padding-block-start: 32px;
    transition: color 0.5s;
  }

  .highlight-text {
    color: colors.$white;
    margin-block-end: 23px;
    transition: opacity 0.5s;
  }

  .highlight-link {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.5s;

    .cta-short-text {
      display: none;
    }
  }

  .highlight-info.active,
  .highlight-info:hover {
    background-color: colors.$white;
    cursor: pointer;

    .highlight-title {
      color: colors.$black;
    }

    .highlight-text {
      color: colors.$off-black;
      opacity: 1;
    }

    .highlight-link {
      opacity: 1;
      pointer-events: all;
    }
  }

  .highlights[data-current-index="0"] .highlight:nth-child(1),
  .highlights[data-current-index="1"] .highlight:nth-child(2),
  .highlights[data-current-index="2"] .highlight:nth-child(3) {
    .photo {
      opacity: 1;
    }
  }
}
